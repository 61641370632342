import React, { useState, useEffect } from 'react';
import "../Styles/AdminChat.css";

export default function AdminChat() {
  const [chatList, setChatList] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [ws, setWs] = useState(null);

    console.log(chatList)
    useEffect(() => {
      const socket = new WebSocket('ws://192.168.0.30:5000'); // Update with your backend WebSocket URL
      setWs(socket);

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
      
        if (data.type === 'new-message' && data.conversationId === selectedChat) {
          setMessages((prevMessages) => [...prevMessages, data.message]);
        } else if (data.type === 'chat-history') {
          setMessages(data.messages);
        }
      };
    
      socket.onclose = () => {
        console.log("WebSocket connection closed");
        setWs(null);
      };
    
      return () => {
        socket.close();
      };
    }, []);

  // Fetch the list of chats
  useEffect(() => {
    fetchChatList();
  }, []);

  const fetchChatList = async () => {
    try {
      const response = await fetch('http://localhost:5000/chats');
      if (response.ok) {
        const data = await response.json();
        console.log("Fetched chat list:", data); // Lägg till en logg för att kontrollera vad du får
        setChatList(data);
      } else {
        console.error('Failed to fetch chat list:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching chat list:', error);
    }
  };

  const fetchMessages = async (conversationId) => {
    console.log("Fetching messages for conversation:", conversationId); // Logga det ID du försöker hämta med
    try {
      const response = await fetch(`http://localhost:5000/chats/${conversationId}`);
      if (response.ok) {
        const data = await response.json();
        console.log("Fetched messages:", data); // Logga de mottagna meddelandena
        setMessages(data);
        setSelectedChat(conversationId);
      } else {
        console.error('Failed to fetch messages:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };
  

  const deleteChat = async (senderId) => {
    try {
      const response = await fetch(`http://localhost:5000/chats/${senderId}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        fetchChatList(); // Update the chat list after deletion
        setMessages([]);
        setSelectedChat(null);
      } else {
        console.error('Failed to delete chat:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting chat:', error);
    }
  };

  const sendMessage = () => {
    if (ws && message.trim() && selectedChat) {
      const newMessage = {
        text: message,
        timestamp: new Date().toISOString(),
        senderId: "admin",
        senderName: "Admin",
        conversationId: selectedChat
      };
  
      ws.send(JSON.stringify(newMessage));
      setMessage('');
    }
  };

  return (
    <div className="admin-chat-container">
      <div className="chat-list">
        <h2>All Chats</h2>
        {chatList.map((chat) => (
          <div
            key={chat._id}
            className={`chat-item ${chat._id === selectedChat ? 'selected' : ''}`}
            onClick={() => fetchMessages(chat._id)}
          >
            <p>{chat.senderName}</p>
            <button onClick={(e) => { e.stopPropagation(); deleteChat(chat._id); }}>Delete</button>
          </div>
        ))}
      </div>

      <div className="chat-window">
        {selectedChat ? (
          <>
            <h2>Chat with {selectedChat}</h2>
            <div className="messages">
              {messages.map((msg, index) => (
                <div key={index} className={`message ${msg.senderName === 'Admin' ? 'sent' : 'received'}`}>
                  <p><strong>{msg.senderName}</strong>: {msg.text}</p>
                  <span>{new Date(msg.timestamp).toLocaleString()}</span>
                </div>
              ))}
            </div>
            <input
              type="text"
              placeholder="Write a message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
            />
          </>
        ) : (
          <p>Select a chat to view messages</p>
        )}
      </div>
    </div>
  );
}
